import request from '@/utils/request'


//申请人是否已经申请
export function isIndividual(params) {
    return request({
        url: '/trade/supplier/isIndividual',
        method: 'get',
        params
    })
}
// 添加自然人
export function addindividual(data) {
    return request({
        url: '/trade/supplier/addindividual',
        method: 'post',
        data
    })
}
// 添加申请列表
export function addapply(data) {
    return request({
        url: '/trade/supplier/addapply',
        method: 'post',
        data
    })
}
// 查询采购商
export function selectpurchaser(params) {
    return request({
        url: '/trade/supplier/selectpurchaser',
        method: 'get',
        params
    })
}
// 查询祱区数据
export function selectregion(params) {
    return request({
        url: '/trade/supplier/selectregion',
        method: 'get',
        params
    })
}
// 自然人列表查询祱区数据
export function regionList(params) {
    return request({
        url: '/trade/supplier/region/list',
        method: 'get',
        params
    })
}
// 活体检测二维码获取
export function verify(params) {
    return request({
        url: '/trade/supplier/face/verify',
        method: 'get',
        params
    })
}
// 活体检测 检测结果获取
export function face(params) {
    return request({
        url: '/trade/supplier/face/get',
        method: 'get',
        params
    })
}
// 查询银行列表
export function selectBanks(params) {
    return request({
        url: '/trade/supplier/selectPaySupportBankZs',
        method: 'get',
        params
    })
}
// 查询银行列表
export function banksList() {
    return request({
        url: '/bank/zs/support/bank',
        method: 'get',
    })
}
// 查询银行列表
export function citysList() {
    return request({
        url: '/bank/zs/find/city',
        method: 'get',
    })
}

// 卖家中心权限管理(子账户列表)
export function auth({entityCharacter, entityId}) {
    return request({
        url: '/trade/pur/auth',
        method: 'get',
        params: {entityCharacter, entityId}
    })
}
// 卖家中心权限管理(签署人列表)
export function signtory({entityCharacter, entityId}) {
    return request({
        url: '/trade/pur/signtory',
        method: 'get',
        params: {entityCharacter, entityId}
    })
}

// 卖家中心添加子账号
export function getparty(data) {
    return request({
        url: '/trade/pur/getparty',
        method: 'get',
        params: data
    })
}

// 卖家中心添加子账号
export function add({entityId, entityCharacter, commercialName, authRoleId, authRoleName, partyId}) {
    return request({
        url: '/trade/pur/add',
        method: 'post',
        data: {entityId, entityCharacter, commercialName, authRoleId, authRoleName, partyId}
    })
}

// 卖家中心添加签署人
export function addsigntory({signerMobile, signerIdCard, signerName, supplierId}) {
    return request({
        url: '/trade/pur/addsigntory',
        method: 'post',
        data: { signerMobile, signerIdCard, signerName, supplierId }
    })
}

// 卖家中心添加签署人
export function deletepartycontrol({entityId, entityCharacter, id}) {
    return request({
        url: '/trade/pur/deletepartycontrol',
        method: 'get',
        params: {entityId, entityCharacter, id}
    })
}

// 根据二级商品品类id获取商品品类层级结构
export function secondFind(secondCategoryId) {
    return request({
        url: '/category/tree/second',
        method: 'get',
        params: {secondCategoryId}
    })
}

// 卖家中心发布商品（二级分类属性查询）
export function querySecondAttr(categorySecondId) {
    return request({
        url: `/category/second/${categorySecondId}/attr`,
        method: 'get'
    })
}

// 卖家中心发布商品（查询品类名称）
export function queryCateroryName(categoryId) {
    return request({
        url: `/category/second/${categoryId}/taxcode`,
        method: 'get'
    })
}

// 计算税费
export function taxCalculate(params) {
    return request({
        url: '/trade/product/taxCalculate',
        method: 'get',
        params
    })
}

// 计算税费
export function queryRegionPolicy(supplierId) {
    return request({
        url: '/tax/regions/policies',
        method: 'get',
        params: {supplierId}
    })
}

// 计算合同最高费用
export function calculateContractMaxAmount(params) {
    return request({
        url: '/trade/product/calculateContractMaxAmount',
        method: 'get',
        params,
    })
}

// 计算合同最高费用
export function releaseProduct(data) {
    return request({
        url: '/merchandise/create',
        method: 'post',
        data,
    })
}

// 商品列表获取
// 要更新类型 上架： PUT_UP 下架： PUT_DOWN 删除： DELETED
export function updateProductStatus(merchandiseId, status) {
    return request({
        url: `/merchandise/${merchandiseId}/modifystatus`,
        method: 'post',
        params: {status}
    })
}

// 商品列表获取
export function productList(params) {
    return request({
        url: '/merchandise/list',
        method: 'get',
        params,
    })
}

// 商品详情
export function productDetail(params) {
    return request({
        url: '/merchandise/detail',
        method: 'get',
        params,
    })
}

// 商品编辑
export function editProductSave(data) {
    return request({
        url: '/merchandise/edit',
        method: 'post',
        data,
    })
}

// 财务管理返回开票信息
export function getsupplierinf(params) {
    return request({
        url: '/trade/pur/getsupplierinf',
        method: 'get',
        params,
    })
}

// 财务管理修改开票信息
export function updateinvoice({entityCharacter, id, invoiceBankName,invoiceBankAcc,invoiceMobile,invoiceEmail,invoiceAddr}) {
    return request({
        url: '/trade/pur/updateinvoice',
        method: 'post',
        data: {entityCharacter, id, invoiceBankName,invoiceBankAcc,invoiceMobile,invoiceEmail,invoiceAddr},
    })
}

// 财务管理修改开票信息
export function updatestatus({id, taxRegStatus}) {
    return request({
        url: '/trade/pur/updatestatus',
        method: 'post',
        data: {id, taxRegStatus},
    })
}

// 卖家中心重发签署短信
export function send({supplierId, signId}) {
    return request({
        url: '/trade/pur/send',
        method: 'get',
        params: {supplierId, signId},
    })
}

// 交易费用计算
export function evaluation(supplierId, grossAmount, taxPolicyId) {
    return request({
        url: '/merchandise/evaluation',
        method: 'get',
        params: {supplierId, grossAmount, taxPolicyId},
    })
}

// 登记收款账号
export function bindBank(data) {
    return request({
        url: '/bank/zs/receipt/bind',
        method: 'post',
        data
    })
}

// 获取银行卡列表
export function bindList(supplierId) {
    return request({
        url: '/trade/pur/bank/account/list',
        method: 'get',
        params: {supplierId}
    })
}

// 添加银行卡
export function addBank({supplierId, bankAccount, bankName, openBankName, openBankNo, bankReservedMobile, zsBankCode}) {
    return request({
        url: '/trade/pur/bank/account/add',
        method: 'post',
        data: {supplierId, bankAccount, bankName, openBankName, openBankNo, bankReservedMobile, zsBankCode}
    })
}

// 设置默认银行卡
export function defaultBank({supplierId, bankAccountId}) {
    return request({
        url: '/trade/pur/bank/account/set/default',
        method: 'post',
        data: {supplierId, bankAccountId}
    })
}

// 切换签属人
export function swSigner({id, supplierId, individualApplyId}) {
    return request({
        url: '/trade/pur/update/signer',
        method: 'get',
        params: {id, supplierId, individualApplyId}
    })
}

// 切换签属人
export function saleamount(supplierId) {
    return request({
        url: '/merchandise/supplier/saleamount',
        method: 'get',
        params: {supplierId}
    })
}

// 获取销售方常用地址
export function usualaddress(supplierId) {
    return request({
        url: '/merchandise/supplier/usualaddress',
        method: 'get',
        params: {supplierId}
    })
}

// 添加供应商（个人类型）
export function personal(data) {
    return request({
        url: '/trade/supplier/add/personal',
        method: 'post',
        data
    })
}

// 添加供应商（个人类型）
export function personalApply(params) {
    return request({
        url: '/trade/supplier/apply/personal',
        method: 'get',
        params
    })
}

// 身份证识别
export function cardOcr(url) {
    return request({
        url: '/trade/supplier/ocr/id/card',
        method: 'post',
        data: {url}
    })
}

// 身份证识别
export function cardBackOcr(url) {
    return request({
        url: '/trade/supplier/ocr/id/card/back',
        method: 'post',
        data: {url}
    })
}