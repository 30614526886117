var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"store-manage"},[_c('p',[_vm._v("商品管理")]),_c('a-row',{attrs:{"type":"flex","justify":"space-between","align":"middle"}},[_c('a-col',{attrs:{"span":6}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.add}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" 添加商品 ")],1)],1),_c('a-col',[_c('a-space',[_c('a-radio-group',{attrs:{"value":_vm.form.currStatusArray},on:{"change":_vm.change}},[_c('a-space',[_c('a-radio-button',{attrs:{"value":null}},[_vm._v(" 全部 ")]),_c('a-radio-button',{attrs:{"value":"PUT_UP"}},[_vm._v(" 上架 ")]),_c('a-radio-button',{attrs:{"value":"PUT_DOWN"}},[_vm._v(" 下架 ")])],1)],1),_c('a-input-search',{attrs:{"placeholder":"请输入商品名称"},on:{"search":function($event){_vm.form.pageNum=1;_vm.getList()}},model:{value:(_vm.form.searchText),callback:function ($$v) {_vm.$set(_vm.form, "searchText", $$v)},expression:"form.searchText"}})],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-table',{attrs:{"data-source":_vm.data,"columns":_vm.stroeColumns,"loading":_vm.loading,"rowKey":function (record,index){return index},"size":"middle","pagination":{
        size: 'default',
        total: _vm.total,
        current: _vm.form.pageNum,
        pageSize: _vm.form.pageSize,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
        showTotal: function (total) { return ("共有 " + total + " 条数据"); },
        showQuickJumper: true, }},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"id",fn:function(text){return [_c('router-link',{staticClass:"id-primary",attrs:{"to":("/commodity?id=" + text + "&entityId=" + (_vm.$route.query.entityId))}},[_vm._v(_vm._s(text))])]}},{key:"action",fn:function(text, record){return [_c('a-space',[(record.currStatus !== 'DELETED')?_c('router-link',{attrs:{"to":("/edit-product?id=" + (record.id) + "&entityId=" + (_vm.$route.query.entityId))}},[_vm._v("编辑")]):_vm._e(),(record.currStatus === 'PUT_DOWN')?_c('a',{on:{"click":function($event){return _vm.updateProductStatus(record.id, 'PUT_UP')}}},[_vm._v("上架")]):_vm._e(),(record.currStatus === 'PUT_UP')?_c('a',{on:{"click":function($event){return _vm.updateProductStatus(record.id, 'PUT_DOWN')}}},[_vm._v("下架")]):_vm._e(),(record.currStatus !== 'DELETED')?_c('a-popconfirm',{attrs:{"title":"确认删除？","okText":"确定","cancelText":"取消"},on:{"confirm":function () { return _vm.updateProductStatus(record.id, 'DELETED'); }}},[_c('a',[_vm._v("删除")])]):_vm._e()],1)]}},{key:"unitPrice",fn:function(text,record){return [_c('span',[_vm._v(_vm._s(text.toFixed(2))+"(元/"+_vm._s(_vm._f("unit")(record.quantityAndMeasure))+")")])]}},{key:"quantityAndMeasure",fn:function(text){return [_c('span',[_vm._v(_vm._s(_vm._f("str")(text)))])]}},{key:"state",fn:function(text){return [(text === 'PUT_UP')?_c('a-badge',{staticClass:"cyan",attrs:{"color":"#1890FF","text":"上架"}}):_vm._e(),(text === 'PUT_DOWN')?_c('a-badge',{staticClass:"default",attrs:{"color":"#c2c0c0","text":"下架"}}):_vm._e(),(text === 'SOLD_OUT')?_c('a-badge',{staticClass:"warning",attrs:{"status":"warning","text":"售罄"}}):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }