export const type = {
  T: '吨',
  K: '千克',
  G: '克',
  S: '台',
  C: '件'
}

export const invoiceState = {
  WAIT_APPLY: "等待申请开票",
  WAIT_AUDIT: '待审核',
  REFUSED: "已拒绝",
  REVIEWED: '已审核',
  PROCESSING: "开票中",
  WAIT_INVOICE: "等待开票",
  INVOICED: "已开票",
}
export const name = {
  'REGISTERED': '已注册',
  'CANCELAPPLIED': '申请注销',
  'APPLYING': '申请中',
  'CANCELLED': '已注销',
}
export const politics = [
  '群众',
  '中共党员',
  '共青团员',
  '民革党员',
  '民盟盟员',
  '民建会员',
  '民进会员',
  '农工党党员',
  '致公党党员',
  '九三学社社员',
  '台盟盟员',
  '无党派人士'
]

export const education = [
  '博士', '研究生', '本科', '大专', '中专', '高中', '初中', '小学', '半文盲'
]

export const transType = {
  'TRUCK': '货车',
  'TRICYCLE': '三轮车',
  'SHIP': '船',
  'AGRICULTURAL_VEHICLE': '农用车',
  'OTHER': '其他',
}

export const logisticsType = {
  'LICENSE_PLATE_PHOTO': '车牌照',
  'FULL_LOAD_WEIGHED_PHOTO': '满载过磅',
  'EMPTY_CAR_WEIGHED_PHOTO': '空车过磅',
  'ARRIVAL_PHOTO': '到达照',
  'LEFT_PHOTO': '出场照',
  'QUALITY_INSPECTION_PHOTO': '质检照',
}

export const channel={
  "ZS":'浙商',
  "PA":'平安',
}

export const dsStatusType={
  "ENABLE":'启用',
  "DISABLE":'禁用',
}