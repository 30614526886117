<template>
  <div class="store-manage">
    <p>商品管理</p>

    <a-row type="flex" justify="space-between" align="middle">
      <a-col :span="6">
        <a-button type="primary" @click="add">
          <a-icon type="plus"></a-icon>
          添加商品
        </a-button>
      </a-col>
      <a-col>
        <a-space>
          <a-radio-group :value="form.currStatusArray" @change="change">
            <a-space>
              <a-radio-button :value="null">
                全部
              </a-radio-button>
              <a-radio-button value="PUT_UP">
                上架
              </a-radio-button>
              <a-radio-button value="PUT_DOWN">
                下架
              </a-radio-button>
            </a-space>
          </a-radio-group>
          <a-input-search placeholder="请输入商品名称" v-model="form.searchText" @search="form.pageNum=1;getList()"></a-input-search>
        </a-space>
      </a-col>
      <a-col :span="24">
        <a-table :data-source="data" :columns="stroeColumns" :loading="loading" :rowKey="(record,index)=>{return index}" @change="handleTableChange" size="middle"
        :pagination="{
          size: 'default',
          total: total,
          current: form.pageNum,
          pageSize: form.pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
          showTotal: total => `共有 ${total} 条数据`,
          showQuickJumper: true, }"
        >
          <template slot="id" slot-scope="text">
            <router-link class="id-primary" :to="`/commodity?id=${text}&entityId=${$route.query.entityId}`">{{text}}</router-link>
          </template>
          <template slot="action" slot-scope="text, record">
            <a-space>
              <router-link v-if="record.currStatus !== 'DELETED'" :to="`/edit-product?id=${record.id}&entityId=${$route.query.entityId}`">编辑</router-link>
              <a v-if="record.currStatus === 'PUT_DOWN'" @click="updateProductStatus(record.id, 'PUT_UP')">上架</a>
              <a v-if="record.currStatus === 'PUT_UP'" @click="updateProductStatus(record.id, 'PUT_DOWN')">下架</a>
              <a-popconfirm v-if="record.currStatus !== 'DELETED'" title="确认删除？" okText="确定" cancelText="取消" @confirm="() => updateProductStatus(record.id, 'DELETED')">
                <a>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
          <template slot="unitPrice" slot-scope="text,record">
            <span>{{text.toFixed(2)}}(元/{{record.quantityAndMeasure | unit}})</span>
          </template>
          <template slot="quantityAndMeasure" slot-scope="text">
            <span>{{text | str}}</span>
          </template>
          <template slot="state" slot-scope="text">
            <a-badge color="#1890FF" v-if="text === 'PUT_UP'" text="上架" class="cyan" />
            <a-badge color="#c2c0c0" v-if="text === 'PUT_DOWN'" text="下架" class="default" />
            <a-badge status="warning" v-if="text === 'SOLD_OUT'" text="售罄" class="warning" />
          </template>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {productList,updateProductStatus} from '@/api/seller'
import { type } from "@/utils/constArr";
const stroeColumns = [
  {
    title: '商品ID',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    title: '名称',
    dataIndex: 'mdseName',
    align: 'left',
  },
  {
    title: '分类',
    dataIndex: 'catFirstName',
    align: 'left',
  },
  {
    title: '单价',
    dataIndex: 'unitPrice',
    scopedSlots: { customRender: 'unitPrice' },
    align: 'right',
  },
  {
    title: '库存',
    dataIndex: 'quantityAndMeasure',
    scopedSlots: { customRender: 'quantityAndMeasure' },
    align: 'right',
  },
  {
    title: '状态',
    dataIndex: 'currStatus',
    scopedSlots: { customRender: 'state' }
  },
  {
    title: '更新时间',
    dataIndex: 'createdTime',
    align: 'left',
  },
  {
    title: '操作',
    dataIndex: 'content',
    align: 'left',
    scopedSlots: { customRender: 'action' }
  },
]
export default {
  filters: {
    unit(val) {
      return val && type[val.charAt(val.length - 1)] || '吨'
    },
    str(val) {
      if (!val) {
        return '吨'
      }
      const char = val.charAt(val.length - 1)
      const num = val.split(char)[0]
      return num + type[char]

    }
  },
  data() {
    return {
      data: [],
      stroeColumns,
      loading: false,
      form: {
        supplierId: this.$route.query.entityId,
        pageNum: 1,
        pageSize: 10,
        currStatusArray: null
      },
        total: 0
    }
  },
  mounted() {
    this.getList()
  }, 
  methods: {
    getList() {
      this.loading = true
      productList(this.form).then(res => {
        console.log(res)
        this.loading = false
        if (res.code === 200) {
          this.data = res.data.list
          this.total = res.data.total
        } else if (res.code === 405) {
          this.data = []
          this.form.pageNum = 1
          this.form.pageSize = 10
          this.total = 0
        }
       
      })
    },
    updateProductStatus(productId, operType) {
      updateProductStatus(productId, operType).then(res => {
        console.log(res)
        this.getList()
      })
    },
    handleTableChange (e) {
        this.form.pageNum = e.current
        this.form.pageSize = e.pageSize
        this.getList()
    },
    change(e) {
      console.log(e.target.value)
      this.form.currStatusArray = e.target.value
      this.form.pageNum=1;
      this.getList()
    },
    add() {
      this.$router.push('/add-product?entityId=' + this.$route.query.entityId)
    }
  }
}
</script>

<style lang="less" scoped>
.store-manage {
  padding: 20px;
  background: #fff;
  min-height: 750px;
  p {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 650;
    margin-bottom: 24px;
  }
  /deep/ .ant-table-wrapper{
    margin-top: 24px;
  }
}
.cyan /deep/ .ant-badge-status-text {
  color: #1890FF;
}
.default /deep/ .ant-badge-status-text {
  color: #c2c0c0;
}
.warning /deep/ .ant-badge-status-text {
  color: #faad14;
}
</style>